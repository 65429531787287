<template>
  <defaultSec :title="'客户旅程'" :returnState="true">
    <div class="add-wrap"><el-button type="danger" size="small">+新建群组</el-button></div>
    <el-row type="flex" justify="space-between">
      <el-col :span="24">
        <el-form :inline="true" :model="formData" size="mini" class="demo-form-inline">
          <el-form-item label="运行状态">
            <el-select v-model="formData.state" placeholder="运行状态">
              <el-option label="运行中" value="0"></el-option>
              <el-option label="已暂停" value="1"></el-option>
              <el-option label="已发布" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="更新方式">
            <el-select v-model="formData.state" placeholder="更新方式">
              <el-option label="手动" value="0"></el-option>
              <el-option label="自动" value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">查询</el-button>
          </el-form-item>
        </el-form>

      </el-col>
      <el-col :span="6">
        <el-input size="mini"
            placeholder="搜索ID/创建者"
            v-model="searchInput">
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
      </el-col>
    </el-row>
    <el-table
        size="mini"
        :data="tableData"
        border
        style="width: 100%;margin-top: 20px;text-align: center">
      <el-table-column
          fixed
          prop="name"
          label="群组名">
      </el-table-column>
      <el-table-column
          prop="count"
          label="人数">
        <template slot-scope="scope">
          <div style="font-size: 16px">{{scope.row.count}}</div>
          <div style="font-size: 12px;font-style: italic"><span :style="{color: scope.row.state==1?'#55a532':'#e50011'}">● </span>{{scope.row.updateTime}}</div>
        </template>
      </el-table-column>
      <el-table-column
          prop="update"
          label="更新方式"
          width="120">
      </el-table-column>
      <el-table-column
          prop="creator"
          label="创建者">
      </el-table-column>
      <el-table-column
          prop="state"
          label="状态">
        <template slot-scope="scope">
          <el-tag size="mini" type="primary" v-if="scope.row.state==1">运行中</el-tag>
          <el-tag size="mini" type="danger" v-else>已暂停</el-tag>
        </template>
      </el-table-column>
      <el-table-column
          prop="time"
          label="创建时间">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          width="120">
        <template slot-scope="scope">
          <el-button @click="handleClick(scope.row.id)" type="text" size="small">查看</el-button>
          <el-dropdown style="margin-left: 16px" placement="bottom-start">
            <el-button type="text" class="el-dropdown-link">
              •••
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>创建旅程</el-dropdown-item>
              <el-dropdown-item>导出数组</el-dropdown-item>
              <el-dropdown-item>删除数组</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
  </defaultSec>
</template>

<script>
import defaultSec from '@c/defaultSection'
export default {
  name: "groupManage",
  data(){
    return {
      formData:{
        state:'',
        update:''
      },
      searchInput:'',
      tableData:[{
        id:1,
        name:'vip会员',
        count:10000,
        updateTime:'2022-09-08',
        update:'手动更新',
        creator:'hh',
        state:1,
        time:'2022-03-02'
      },{
        id:1,
        name:'复购三次',
        count:43276,
        updateTime:'2022-09-08',
        update:'自动更新',
        creator:'广告费',
        state:0,
        time:'2022-03-02'
      }]
    }
  },
  components:{defaultSec},
  methods:{
    onSubmit(){

    },
    handleClick(id){
      this.$router.push({path: '/tripManageDetail/:id', name: 'tripManageDetail', params: {id: id}})

    }
  }
}
</script>

<style scoped>
  .add-wrap{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }
</style>